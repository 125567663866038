(function($) {
	'use strict';

	var dorySrc = 'images/finding-dory-lrg.png'; 
	var ver = getInternetExplorerVersion();

	if (ver < 0 || ver >= 9) {
		$(document).foundation('interchange', 'reflow');
	} else {
		$('#dory').attr('src', dorySrc);
	}



	function getInternetExplorerVersion()
	    // Returns the version of Internet Explorer or a -1
	    // (indicating the use of another browser).
	{
	    var rv = -1; // Return value assumes failure.
	    if (navigator.appName == 'Microsoft Internet Explorer') {
	        var ua = navigator.userAgent;
	        var re = new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})");
	        if (re.exec(ua) != null)
	            rv = parseFloat(RegExp.$1);
	    }
	    return rv;
	}

})(jQuery);